<div
  class="global-container"
  [ngClass]="{
    'global-container--desktop': isDesktop,
  }"
>
  <button class="logout-button" mat-flat-button type="button" (click)="logout()">
    <mat-icon>logout</mat-icon>
    Log out
  </button>

  <header>
    <img src="/assets/img/logos/waltio-white.svg" alt="waltio" width="150px" height="40px" />
    <img src="/assets/img/icons/cross.svg" alt="logo" />
    <img src="/assets/img/logos/ledger-white.svg" alt="ledger" width="150px" height="40px" />
  </header>

  <section
    [ngClass]="{
      'container': isDesktop,
      'mobile-container': isMobile || isTablet,
    }"
  >
    <div
      [ngClass]="{
        'steps': isDesktop,
        'mobile-steps': isMobile || isTablet,
      }"
    >
      <div class="step">
        <div class="step__title">
          <div class="step__number">1</div>
          <h3>Waltio identified {{ extendedAccounts.length }} wallets</h3>
        </div>
        <p>Connect your Ledger accounts to sync all your transactions</p>
      </div>

      @if (isDesktop) {
        <mat-divider class="vertical-divider" [vertical]="true"></mat-divider>
      }
      @if (isMobile || isTablet) {
        <mat-divider></mat-divider>
      }

      <div class="step">
        <div class="step__title">
          <div class="step__number">2</div>
          <h3>
            Generate your report at
            <a href="https://waltio.app.link/ledger-redirection" target="_blank" rel="noopener">tax.waltio.com</a>
          </h3>
        </div>
        <p>Click on the link to sync your others exchanges or wallets and generate your tax report</p>
      </div>
    </div>

    <!-- TABLE -->
    @if (extendedAccounts.length > 0) {
      <div class="table-container">
        <table mat-table [dataSource]="extendedAccounts" aria-label="">
          <ng-container matColumnDef="token">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let extendedAccount">
              <div class="f-row">
                <img
                  class="coin-icon"
                  src="https://icons.waltio.com/token/{{
                    currencies.get(extendedAccount.account.currency)?.ticker || extendedAccount.account.id | lowercase
                  }}"
                  default="/assets/img/icons/coins/default.svg"
                  [alt]="currencies.get(extendedAccount.account.currency)?.ticker || extendedAccount.account.id"
                  width="28"
                  height="28"
                />
                <span class="token-label">{{
                  currencies.get(extendedAccount.account.currency)?.name | titlecase
                }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let extendedAccount">
              @if (extendedAccount.account.currency === 'bitcoin' || extendedAccount.account.currency === 'cardano') {
                {{ extendedAccount.realAddress | truncate }}
              }
              @if (extendedAccount.account.currency !== 'bitcoin' && extendedAccount.account.currency !== 'cardano') {
                {{ extendedAccount.account.address | truncate }}
              }
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let extendedAccount; let i = index">
              <div class="actions">
                @if (!extendedAccount.adding && !extendedAccount.added) {
                  <button
                    type="button"
                    mat-flat-button
                    color="primary"
                    [disabled]="
                      unsupportedAccounts.includes(extendedAccount.account.currency) || extendedAccount.checking
                    "
                    (click)="checkAddress(extendedAccount, i)"
                  >
                    @if (!extendedAccount.checking && unsupportedAccounts.includes(extendedAccount.account.currency)) {
                      Not supported
                    }
                    @if (!extendedAccount.checking && !unsupportedAccounts.includes(extendedAccount.account.currency)) {
                      Add wallet
                    }
                    @if (extendedAccount.checking) {
                      Checking
                    }
                  </button>
                }
                @if (extendedAccount.adding) {
                  <div class="loader-container">
                    <div class="loader">
                      <svg class="circular">
                        <circle
                          class="path"
                          cx="25"
                          cy="25"
                          r="10"
                          fill="none"
                          stroke-width="2"
                          stroke-miterlimit="10"
                        ></circle>
                      </svg>
                    </div>
                  </div>
                }
                @if (extendedAccount.added) {
                  <div class="success-chip">Added</div>
                }
                @if (unsupportedAccounts.includes(extendedAccount.account.currency)) {
                  <img
                    class="pointer info"
                    src="/assets/img/icons/info-bubble.svg"
                    alt="info"
                    matTooltip="Help us to support this wallet"
                    matTooltipPosition="above"
                    (click)="goToFeedback()"
                  />
                }
              </div>
            </td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
        </table>
      </div>
    }
  </section>
</div>

@if (isMobile || isTablet) {
  <div class="ctas">
    <button type="button" mat-flat-button color="primary" (click)="goToWaltio()">Get my report</button>
  </div>
}

<app-custom-toast
  [error]="toastError"
  [showToast]="showToast"
  [type]="toastType"
  (closeToast)="showToast = false"
></app-custom-toast>
