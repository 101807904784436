import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { catchError, EMPTY, map, Subject, takeUntil } from 'rxjs';
import { Tag } from './models/tag.model';
import { User } from './models/user.model';
import { IntercomService } from './services/intercom.service';
import { TrackingService } from './services/tracking.service';
import { WaltioService } from './services/waltio.service';
import { AuthService, User as AuthUser } from '@auth0/auth0-angular';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: `app-root`,
  templateUrl: `./app.component.html`,
})
export class AppComponent implements OnInit, OnDestroy {
  user: User;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly waltioService: WaltioService,
    private readonly trackingService: TrackingService,
    private readonly intercomService: IntercomService,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        map((event: any) => {
          if (event instanceof NavigationEnd) {
            // Send GTM tag
            if (event.url !== `/` && !event.url.includes(`/callback`)) {
              if (!this.user) {
                this.waltioService
                  .getUser()
                  .pipe(
                    takeUntil(this.destroy$),
                    map((user: User) => {
                      this.pushTag(user);
                    }),
                    catchError((error: any) => {
                      this.pushTag();

                      return EMPTY;
                    })
                  )
                  .subscribe();
              } else {
                this.pushTag(this.user);
              }
            }
          }
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.loadTrackers();

    this.authService.user$
      .pipe(
        takeUntil(this.destroy$),
        map((user: AuthUser) => {
          this.intercomService.updateSettings(user.email, user.sub);
        })
      )
      .subscribe();

    this.waltioService
      .getUser()
      .pipe(
        takeUntil(this.destroy$),
        map((user: User) => {
          this.user = user;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  pushTag(user?: User): void {
    const tag: Tag = { event: `page_view` };

    if (user) {
      tag.loginStatus = `logged in`;
      tag.market = user.fiscalResidency;
      tag.plan = user.plan;
    } else {
      tag.loginStatus = `logged out`;
      tag.market = ``;
      tag.plan = ``;
    }

    this.trackingService.pushTag(tag);
  }

  loadTrackers(): void {
    this.trackingService.loadTrackers();
  }
}
